import '../styles/app.css';

import { createApp } from 'vue'

import Header from "@/components/Site/Header/Header.vue";
import Button from "@spongecakesltd/ui/dist/vue/Shop/Button/Button.vue";
import FlashMessage from "@spongecakesltd/ui/dist/vue/Shop/FlashMessage/FlashMessage.vue";
import Consent from "../components/Site/Consent/Consent.vue";

createApp({ components: {
    'scl-header': Header,
    'scl-button': Button,
    'scl-flashmessage': FlashMessage,
    'scl-consent': Consent
}}).mount('#app');


const reset_link = document.querySelector("#session_reset");
if(reset_link !== null) {
    reset_link.addEventListener("click", (e) => {
        e.preventDefault();
        if(confirm('Are you sure you want to reset your session? This will empty your basket and log you out.')) {
            window.location.href = e.target.href;
        }
    })
}

window.addEventListener('DOMContentLoaded', () => {
    // let button = document.querySelector("#consent button");
    //
    // if(button !== null) {
    //     button.addEventListener('click', () => {
    //         const cookiename = 'cookie-consent';
    //         if(!document.cookie.match('(^|;) ?' + cookiename + '=([^;]*)(;|$)')) {
    //             // 60 days hence
    //             let d = new Date;
    //             d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 60);
    //
    //             // Set One
    //             document.cookie = "cookie-consent=1;path=/;expires=" + d.toUTCString();
    //
    //             let elem = document.querySelector("#consent");
    //             if(elem !== null) {
    //                 elem.addEventListener('click', function(e){
    //                     let elm = document.querySelector("#consent") as HTMLElement;
    //                     if(elm !== null) {
    //                         elm.style.display = 'none';
    //                     }
    //                 });
    //             }
    //         }
    //     })
    // }

    let signup_button = document.querySelector("#signup button");
    if(signup_button !== null) {
        signup_button.addEventListener('click', () => {
            window._klOnsite = window._klOnsite || [];
            window._klOnsite.push(['openForm', 'ViUMyV']);
        });
    }
});