<template>
  <Drawer origin="right" ref="search_drawer" @open="onOpen">
    <div class="p-2 h-full overflow-y-auto">
      <Autocomplete :api_key="api_key" :app_key="app_key" :index_prefix="index_prefix"></Autocomplete>
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import Autocomplete from "@/components/Site/Search/Autocomplete.vue";
import Drawer from "@spongecakesltd/ui/dist/vue/Common/Drawer/Drawer.vue";

const props = defineProps({
  api_key: {Type: String, default: null},
  app_key: {Type: String, default: null},
  index_prefix: {Type: String, default: 'prod'}
});

const search_drawer = ref(null);
const focus = ref(null);

const open = () => search_drawer.value.open = true;

defineExpose({open});

onMounted(() => {
  document.addEventListener('sponge:search:open', open);
});

const onOpen = () => {
  const el = document.getElementById("sponge-search-input");
  if(el !== null) {
    el.focus();
  }
}

</script>